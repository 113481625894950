import {
  SessionDispatchingDataFragment,
  SessionStatusFilterEnum,
} from '@vette/data-access';
import { ColumnsType } from 'antd/es/table';
import { Tooltip, Text } from '@vette/ui-components';
import { formatDistance, intervalToDuration } from 'date-fns';
import { formatInUTC } from '@vette/frontend-utils';
import { EndSession } from './EndSession';

export const columns = (
  sessionStatus: SessionStatusFilterEnum
): ColumnsType<SessionDispatchingDataFragment> => [
  {
    title: 'Session ID',
    dataIndex: 'id',
  },
  {
    title: 'Location',
    dataIndex: 'location',
  },
  {
    title: 'Company',
    key: 'companyName',
    render: (_, { jobOffer }) => {
      return jobOffer?.client.companyName;
    },
  },
  {
    title: 'Created at',
    key: 'createdAt',
    render: (_, { createdAt }) => {
      return (
        <>
          <Tooltip title={formatInUTC(createdAt)}>
            <Text>
              {formatDistance(new Date(createdAt), new Date()).replace(
                'about',
                '~'
              ) + ' ago'}
            </Text>
          </Tooltip>
        </>
      );
    },
  },
  {
    title: 'Duration',
    key: 'activeDuration',
    render: (_, { activeDuration }) => {
      if (!activeDuration) return 'no duration';

      const formattedDuration = intervalToDuration({
        start: 0,
        end: activeDuration,
      });

      const { hours, minutes, seconds } = formattedDuration;

      return `${hours}h ${minutes}m ${seconds}s`;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (_, { canceled, status }) => {
      return canceled ? `CANCELED - ${status}` : status;
    },
  },
  ...(sessionStatus === SessionStatusFilterEnum.IN_PROGRESS
    ? [
        {
          title: 'Action',
          dataIndex: 'id',
          render: (id: string) => {
            return <EndSession sessionId={id} key={id} />;
          },
        },
      ]
    : []),
];
