import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
  BaseSyntheticEvent,
} from 'react';
import { ConfigProvider, TablePaginationConfig, Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import { NetworkStatus } from '@apollo/client';
import { theme } from '../../theme';
import { PageCardRef } from '../../PageCard';
import { NoData, NoResults } from '../Empty';

import * as S from './styles';

const Placeholder = () => (
  <S.Placeholder>
    <S.PlaceholderSkeleton isHeader />
    <S.PlaceholderSkeleton />
    <S.PlaceholderSkeleton />
  </S.Placeholder>
);

export type Props<TRecordType> = {
  disableHoverAnimation?: boolean;
  hasActiveFilters?: boolean;
  forbidExpand?: boolean;
  header?: ReactElement;
  headerGap?: number;
  rowPadding?: number;
  networkStatus?: NetworkStatus;
  loading?: boolean;
  pagination?: false | TablePaginationConfig;
  pageCardRef?: PageCardRef | null;
  getRowLink?: (record: TRecordType) => string | undefined;
  onRowClick?: (rowKey: string) => void;
  empty?: () => React.ReactNode;
} & TableProps<TRecordType>;

export type TableRef = {
  focusSelectedRow: (rowKey: string) => void;
};

const LinkContext = createContext<string | null>(null);

export const Table = forwardRef(
  // eslint-disable-next-line @typescript-eslint/ban-types
  function <TRecordType extends object>(
    {
      disableHoverAnimation,
      pageCardRef,
      columns,
      dataSource,
      loading,
      networkStatus,
      hasActiveFilters,
      header,
      headerGap,
      rowPadding,
      pagination,
      getRowLink,
      onRowClick,
      forbidExpand,
      empty,
      ...props
    }: Props<TRecordType>,
    ref?: Ref<TableRef>
  ) {
    const tableRef = useRef<HTMLDivElement>(null);
    const rowsRef = useRef<Record<string, HTMLTableRowElement>>({});
    const [previousLoadedDataSource, setPreviousLoadedDataSource] =
      useState(dataSource);

    const isInitialLoading = networkStatus === NetworkStatus.loading;
    const isSubsequentLoading =
      networkStatus && networkStatus < NetworkStatus.poll && !isInitialLoading;

    useEffect(() => {
      if (!isInitialLoading && !isSubsequentLoading)
        setPreviousLoadedDataSource(dataSource);
    }, [dataSource, isInitialLoading, isSubsequentLoading]);

    useImperativeHandle(ref, () => ({
      focusSelectedRow: (rowKey: string) => {
        setTimeout(() => {
          const row = rowsRef.current[rowKey];
          if (row) {
            row.scrollIntoView({ block: 'center', inline: 'nearest' });
            row.animate(
              { backgroundColor: [theme.colors.yellow20, theme.colors.white] },
              10000
            );
          }
        }, 0);
      },
    }));

    return (
      <ConfigProvider
        renderEmpty={empty ? empty : hasActiveFilters ? NoResults : NoData}
      >
        <S.Wrapper
          $disableHoverAnimation={disableHoverAnimation}
          $gap={headerGap}
          $rowPadding={rowPadding}
        >
          {header}
          {loading || isInitialLoading ? (
            <Placeholder />
          ) : (
            <AntTable
              rowKey="id"
              ref={tableRef}
              columns={columns}
              dataSource={
                !dataSource?.length && isSubsequentLoading
                  ? previousLoadedDataSource
                  : dataSource
              }
              loading={isSubsequentLoading}
              pagination={pagination}
              onChange={(_, __, ___, extra) => {
                if (extra.action === 'paginate') {
                  if (pageCardRef) pageCardRef.scrollToTop();
                }
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onRow={(args: any) => {
                return {
                  onClick: (e: BaseSyntheticEvent) => {
                    const selectDropdownClassNames = [
                      'ant-select-item-option-content',
                      'ant-select-selection-item',
                    ];

                    if (
                      typeof e.target.className === 'string' &&
                      !e.target.className
                        .split(' ')
                        .some((className: string) =>
                          selectDropdownClassNames.includes(className)
                        )
                    )
                      onRowClick?.(args?.id);
                  },
                };
              }}
              components={{
                body: {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  row: (rowProps: any) => {
                    const record = rowProps.children[0]?.props.record;
                    const id = record?.id;
                    return (
                      <LinkContext.Provider
                        value={(() => {
                          if (typeof getRowLink === 'function')
                            return getRowLink(record) || null;
                          return null;
                        })()}
                      >
                        <tr
                          {...rowProps}
                          ref={el =>
                            id &&
                            (rowsRef.current[id] = el as HTMLTableRowElement)
                          }
                        />
                      </LinkContext.Provider>
                    );
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  cell: (cellProps: any) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const link = useContext(LinkContext);
                    const { children, ...restCellProps } = cellProps;
                    return (
                      <td {...restCellProps}>
                        <div className="table-cell-content">{children}</div>
                        {link && dataSource?.length ? (
                          <a className="table-cell-link" href={link}>
                            {' '}
                          </a>
                        ) : null}
                      </td>
                    );
                  },
                },
              }}
              {...(forbidExpand
                ? {
                    // hack because of antd bug - not allowing to disable expand icon when "children" prop is present
                    expandable: { childrenColumnName: 'non_existing' },
                  }
                : {})}
              {...props}
            />
          )}
        </S.Wrapper>
      </ConfigProvider>
    );
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
) as <TRecordType extends object>(
  p: Props<TRecordType> & { ref?: Ref<TableRef> }
) => ReactElement;
