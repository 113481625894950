import {
  Button,
  Form,
  FormItem,
  Space,
  Input,
  PhoneInput,
} from '@vette/ui-components';
import React from 'react';
import { useAdminProcessApplicationMutation } from '@vette/data-access';
import { message } from '@vette/frontend-utils';

import * as S from './styles';

type Props = { visible: boolean; onCancel?: () => void };

type FormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  jobOfferId: string;
};

export const EnterApplicationManuallyModal: React.FC<Props> = ({
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm<FormValues>();
  const [adminProcessApplication, processApplicationMutation] =
    useAdminProcessApplicationMutation({
      onCompleted: ({ adminProcessApplication }) => {
        switch (adminProcessApplication?.__typename) {
          case 'SuccessResult':
            message.success(adminProcessApplication.message);
            form.resetFields();
            onCancel?.();
            break;

          case 'ValidationError':
            message.error(adminProcessApplication.messages[0]);
            break;

          case 'NotFoundError':
            message.error(adminProcessApplication.message);
            break;

          default:
            message.error(
              'Application creation failed due to unexpected error'
            );
            break;
        }
      },
    });

  const handleSubmit = async (values: FormValues) => {
    await adminProcessApplication({ variables: { application: values } });
  };

  return (
    <S.Modal
      width="624px"
      destroyOnClose
      onCancel={() => {
        form.resetFields();
        onCancel?.();
      }}
      footer={
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => form.submit()}
          loading={processApplicationMutation.loading}
        >
          Add Application
        </Button>
      }
      visible={visible}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Space $fullWidth direction="vertical" size={22} align="start">
          <FormItem
            label="Applicant's First Name"
            name="firstName"
            rules={[
              { required: true, message: "Applicant's First Name is required" },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="Applicant's Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Applicant's Last Name is required" },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            label="Phone Number"
            name="phone"
            rules={[{ required: true, message: 'Phone Number is required' }]}
          >
            <PhoneInput />
          </FormItem>
          <FormItem
            label="Job Offer Id"
            name="jobOfferId"
            rules={[{ required: true, message: 'Job offer ID is required' }]}
          >
            <Input />
          </FormItem>
        </Space>
      </Form>
    </S.Modal>
  );
};
